<template>
  <div class="text-center com-home-QuestionSolution">
    <h1 class="title" v-if="getBrowser == 'pc'">微海翘财正在为连锁企业解决的问题</h1>
    <h1 class="titlePhone" v-if="getBrowser == 'phone'">微海翘财
      <br>
      为连锁企业解决的问题</h1>
    <div class="matte-bg position-absolute start-50 translate-middle-x"></div>
    <div class="solution-panel position-absolute start-50 translate-middle-x">
      <a-row class="solution-title" v-if="getBrowser == 'pc'">
        <a-col :span="6">您可能遇到</a-col>
        <a-col :span="18" class="text-center">我们的解决方案</a-col>
      </a-row>
      <a-row class="solution-content text-start" v-if="getBrowser == 'pc'">
        <a-col :span="6">
          <div :class="`solution-active active-${selectIndex}`"></div>
          <ul>
            <li
              :class="{ active: selectIndex === index }"
              v-for="(item, index) in source.list"
              :key="index"
              @click="selectClick(index)"
            >
              <h3>{{ item.title }}</h3>
              <span>{{ item.desc }}</span>
            </li>
          </ul>
        </a-col>
        <a-col :span="18" class="text-left">
          <components
            :is="source.list[selectIndex].component"
            class="solution-components"
          />
        </a-col>
      </a-row>
      <div v-if="getBrowser == 'phone'" class="phonebox">
          <div class="buttonBox">
            <div  v-for="(item, index) in source.list"  :class="{ active2: index == isActive }" :key="index" @click="changeButton(index)">
              <span>{{item.title}}</span>
            </div>
          </div>
          <van-swipe   type="card" :loop="true" @change="onChange" :autoplay="5000" ref="swiper">
            <van-swipe-item>
              <div class="swiperBox">
                <p>我们的解决方案</p>
                <p>支出一体化</p>
                <p>改用移动报销，员工随时提单、查询报销状态，公司报销制度；共享办公，由审核凭证变为单据审核，自动生成凭证、报表；</p>
                <img src="../../assets/zhichu.png" alt="">
              </div>
            </van-swipe-item>
            <van-swipe-item>
              <div class="swiperBox">
                <p>我们的解决方案</p>
                <p>资产一体化</p>
                <p>资产实物、盘点核算一体化，实时监控各网点资产情况；</p>
                <img src="../../assets/zichan.png" alt="">
              </div>
            </van-swipe-item>
            <van-swipe-item>
              <div class="swiperBox">
                <p>我们的解决方案</p>
                <p>自动化 合规化</p>
                <p>利用OCR识别技术、银企直联等方式，提升财务管理、结算效率；资金CA认证、审计合规保证；</p>
                <img src="../../assets/zidong.png" alt="">
              </div>
            </van-swipe-item>
            <van-swipe-item>
              <div class="swiperBox">
                <p>我们的解决方案</p>
                <p>智能经营分析</p>
                <p>利用会计引擎，系统生成凭证，实现业财一体化；借助分析工具，即刻呈现经营分析结果，为决策赋能；</p>
                <img src="../../assets/zhineng.png" alt="">
              </div>
            </van-swipe-item>
            <van-swipe-item>
              <div class="swiperBox">
                <p>我们的解决方案</p>
                <p>营收一体化</p>
                <p>利用大数据技术，实现自动化、精准化营收对账；营收对账后，实现营收一体化应用，自动生成凭证；</p>
                <img src="../../assets/yingshou.png" alt="">
              </div>
            </van-swipe-item>
    
            <!-- <van-swipe-item>
              <img src="../../assets/支出一体化.png" alt="">
            </van-swipe-item>   
            <van-swipe-item>
              <img src="../../assets/资产一体化.png" alt="">
            </van-swipe-item>
            <van-swipe-item>
              <img src="../../assets/自动化合规化.png" alt="">  
            </van-swipe-item>
            <van-swipe-item>
              <img src="../../assets/智能经营分析.png" alt="">
            </van-swipe-item>
            <van-swipe-item>
              <img src="../../assets/营收一体化.png" alt="">
            </van-swipe-item> -->
          </van-swipe>
      </div>
    </div>
  </div>
</template>
<script>
import { components } from "@/plugins/utils";
export default {
  ...components({
    "home/QuestionSolution": [
      "QuestionOne",
      "QuestionTwo",
      "QuestionThree",
      "QuestionFour",
      "QuestionFive",
    ],
  }),
  data() {
    return {
      source: {
        list: [
          {
            title: "高度不集中",
            desc: "财务未共享，核算方式原始",
            component: "QuestionOne",
          },
          {
            title: "营业网点多",
            desc: "资产实物管理失控，账实不符。盘点困难",
            component: "QuestionTwo",
          },
          {
            title: "效率低下",
            desc: "财务管理效率低下，安全无法保证",
            component: "QuestionThree",
          },
         
          {
            title: "业务财务相割裂",
            desc: "财务分析较为传统，基本靠手工",
            component: "QuestionFour",
          },
          {
            title: "门店收银结算方式多",
            desc: "微信、支付宝、信用卡等诸多结算方式、财务对账繁琐、工作量大",
            component: "QuestionFive",
          },
        ],
      },
      isActive : 0,
      selectIndex: 1,
      io: null,
    };
  },
  mounted() {
    this.initData();
  },
  beforeDestroy() {
    if (this.io) {
      this.io.unobserve(this.$el);
      this.io.disconnect();
    } else {
      window.removeEventListener("scroll", this.onscroll);
    }
  },
  methods: {
    initData() {
      // 根据屏幕高度采用不同的方案
      const winHeight = document.documentElement.offsetHeight;
      if (winHeight < 1000) {
        let step = 0;
        this.io = new IntersectionObserver(() => {
          if (step === 2 || (winHeight <= 720 && step === 1)) {
            this.$emit("scrollview");
            this.selectClick(0);
          }
          step += 1;
        });
        this.io.observe(this.$el);
      } else {
        window.addEventListener("scroll", this.onscroll);
      }
    },
    onChange(e){
      this.isActive = e;
    },
    changeButton(val){
       this.isActive = val;
       this.$refs.swiper.swipeTo(val);
    },
    onscroll() {
      if (document.documentElement.scrollTop > 50) {
        this.$emit("scrollview");
        this.selectClick(0);
        window.removeEventListener("scroll", this.onscroll);
      }
    },
    selectClick(index) {
      this.selectIndex = index;
    },
  },
};
</script>
<style lang="less" scoped>
@media screen and (min-width: 320px){
  .com-home-QuestionSolution {
  height: 775px;
  background: url(../../../public/img/QuestionSolution_bg.jpg) no-repeat center center;
  background-size: cover;
  .titlePhone {
    font-family: PingFangSC-Medium;
    color: #ffffff;
    font-weight: 400;
    text-align: left;
    padding: 50px 0 20px 32px;
    font-size:26px;
  }
  .matte-bg {
    width: 100%;
    filter: blur(1px);
    height: 665px;
    border-radius: 10px 10px 0 0;
  }
  .phonebox{
    width:100%;
   .van-swipe{
      width:100%;
      border-radius: 12px;
      .swiperBox{
        height:450px;
        width:100%;
        padding:20px 22px;
        background:#F3F4F4;
        border-radius:15px;
        :first-child{
          width: 122px;
          height: 25px;
          flex-shrink: 0;
          border-radius: 12px 0;
          position:absolute;
          top:0;left:0;
          background: #ddd;
          color: #3e4c5a;
          font-family: "PingFang SC";
          font-size: 13px;
          text-align:center;
          font-weight: 500;
          line-height: 25px;
          opacity: 0.6972;
        }
        :nth-child(2){
          color:#00A497;
          margin-top:30px;
          font-family: "Source Han Sans CN";
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px;
        }
        :nth-child(3){
         display:flex;
         font-size:14px;
         justify-content:space-betweeen;
        }


      }
      img{
        width:100%;
        height:228px;
      }
    }
    .buttonBox{
      width:100%;
      justify-content:flex-start;
      align-items: space-around;
      display: flex;
      padding-left:20px;
      margin-bottom:1rem;
      flex-wrap: wrap;
      div{
        border: 1px solid white;
        border-radius: 20px;
        height:28px;
        min-width: 80px;
        color: #ffffff;
        font-family: "PingFang SC";
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        padding: 5px 10px;
        width:auto;
        display: flex;
        justify-content: center;
        align-items: center;
        color:white;
        margin:0 5px 16px 10px;
        padding:0 16px;
      
      };
      .active2{
        background:#00A497;
        border:1px solid #00A497 !important;
        span{
          font-weight: 500 ;
          font-size: 14px ;
        };
      
        }
    }
  }
 
  .solution-panel {
    width: 100%;
    .solution-title {
      padding: 15px 0;
      font-family: PingFangSC-Medium;
      font-size: 20px;
      color: #ffffff;
      font-weight: 500;
    }
    .solution-content {
      background-color: #f3f4f4;
      border-radius: 10px 10px 0 0;
      padding: 40px;
      height: 30px;
      .ant-col-6 {
        .solution-active {
          position: absolute;
          border-radius: 10px;
          background: white;
          width: 30px;
          height: 96px;
          transition: 0.5s;
          &::before {
            transition: 0.5s;
            content: " ";
            width: 20px;
            height: 20px;
            background: white;
            position: absolute;
            margin-left: 270px;
            margin-top: 35px;
            float: left;
            transform: rotate(45deg);
          }
          &.active-1,
          &.active-4 {
            &::before {
              margin-top: 45px;
            }
          }

          &.active-1 {
            margin-top: 95px;
            height: 117px;
          }
          &.active-2 {
            margin-top: 212px;
          }
          &.active-3 {
            margin-top: 308px;
          }
          &.active-4 {
            margin-top: 404px;
            height: 117px;
          }
        }
        ul {
          position: relative;
          margin: 0;
          padding: 0;
          li {
            list-style: none;
            border-bottom: 1px solid rgba(230, 231, 231, 0.5);
            padding-bottom: 20px;
            padding: 20px;
            cursor: pointer;
            &.active {
              h3 {
                color: @primary-color;
              }
            }
            h3 {
              font-size: 16px;
              color: #202121;
              font-weight: 500;
              padding: 0;
              padding-bottom: 10px;
              margin: 0;
            }
            span {
              font-size: 14px;
              color: #949898;
            }
          }
        }
      }
    }
  }
  .solution-components {
    overflow: hidden;
    position: relative;
    height: 522px;
    visibility: hidden;
    /deep/ img {
      position: absolute;
    }
  }
}
}
@media screen and (min-width: 1024px) {
  .com-home-QuestionSolution {
  height: 839px;
  background: url(../../../public/img/QuestionSolution_bg.jpg) no-repeat center
    center;
  background-size: cover;
  .title {
    font-family: PingFangSC-Medium;
    font-size: 28px;
    color: #ffffff;
    font-weight: 500;
    padding-top: 80px;
    padding-bottom: 40px;
  }
  .matte-bg {
    width: 1240px;
    background: rgba(255, 255, 255, 0.2);
    filter: blur(1px);
    height: 665px;
    border-radius: 10px 10px 0 0;
  }
  .solution-panel {
    width: 1200px;
    .solution-title {
      padding: 15px 0;
      font-family: PingFangSC-Medium;
      font-size: 20px;
      color: #ffffff;
      font-weight: 500;
    }
    .solution-content {
      background-color: #f3f4f4;
      border-radius: 10px 10px 0 0;
      padding: 40px;
      height: 605px;
      .ant-col-6 {
        .solution-active {
          position: absolute;
          border-radius: 10px;
          background: white;
          width: 280px;
          height: 96px;
          transition: 0.5s;
          &::before {
            transition: 0.5s;
            content: " ";
            width: 20px;
            height: 20px;
            background: white;
            position: absolute;
            margin-left: 270px;
            margin-top: 35px;
            float: left;
            transform: rotate(45deg);
          }
          &.active-1,
          &.active-4 {
            &::before {
              margin-top: 45px;
            }
          }

          &.active-1 {
            margin-top: 95px;
            height: 117px;
          }
          &.active-2 {
            margin-top: 212px;
          }
          &.active-3 {
            margin-top: 308px;
          }
          &.active-4 {
            margin-top: 404px;
            height: 117px;
          }
        }
        ul {
          position: relative;
          margin: 0;
          padding: 0;
          li {
            list-style: none;
            border-bottom: 1px solid rgba(230, 231, 231, 0.5);
            padding-bottom: 20px;
            padding: 20px;
            cursor: pointer;
            &.active {
              h3 {
                color: @primary-color;
              }
            }
            h3 {
              font-size: 16px;
              color: #202121;
              font-weight: 500;
              padding: 0;
              padding-bottom: 10px;
              margin: 0;
            }
            span {
              font-size: 14px;
              color: #949898;
            }
          }
        }
      }
    }
  }
  .solution-components {
    overflow: hidden;
    position: relative;
    height: 522px;
    visibility: hidden;
    /deep/ img {
      position: absolute;
    }
  }
}
}

</style>